import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "business-review/services/customer.service";
 
@Injectable({
  providedIn: "root",
})
export class TranslationService {
  private userPreference: string = "en"; // Default language
  userId: string;
 
  constructor(
    public translate: TranslateService,
    private customerService: CustomerService
  ) {}
 
  translateLanguage() {
    this.translate.use(this.userPreference);
    const storedAccountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));
    this.userId = storedAccountInfo?.payload?.account?.localAccountId;
    if(this.userId){
      this.customerService.getUserPreferencesObservable(this.userId).subscribe(userPreference => {
        if (userPreference && userPreference.langKey) {
          this.translate.use( userPreference.langKey);
        }
      })
    } 
  }
}