<div class="footer">
    <div class="footer__currentYear">
        <p class="footer__copyright">&copy; Ecolab {{currentYear}}</p>
    </div>
    <div class="footer__links">
        <a href="https://www.ecolab.com/privacy-policy" id="privacy-policy-link" target="_blank" rel="noopener"> {{ 'EBR.PRIVACYPOLICY' | translate}} </a>
        <a href="https://www.ecolab.com/terms-of-use" id="term-link" target="_blank" rel="noopener"> {{ 'EBR.TERMS' | translate}} </a>
    </div>
    <div class="footer__logo">
        <img alt="Ecolab Logo" class="logo" src="assets/images/logo.png" />
    </div>
</div>