import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "services/translation.service";
@Component({
  selector: "app-unauthorized",
  templateUrl: "./unauthorized.component.html",
  styleUrl: "./unauthorized.component.scss",
})
export class UnauthorizedComponent {
  constructor(
    public translateService: TranslationService,
    public translate: TranslateService
  ) {}
  returnToMain() {
    window.location.href = "https://www.ecolab.com";
  }
  ngOnInit() {
    this.translateService.translateLanguage();
  }
}
