import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "business-review/services/customer.service";
import { TranslationService } from "services/translation.service";
@Component({
  selector: "app-total-value",
  templateUrl: "./total-value.component.html",
  styleUrl: "./total-value.component.scss",
})
export class TotalValueComponent {
  @Input() totalValue: string;
  @Input() totalValueDeliveredInMillion: string;
  @Input() totalOpportunityInMillion: string;
  @ViewChild('currencySymbolOut', { static: false }) currencySymbolOut: ElementRef;
  userPreference:{currencyCode:string,currencySymbol:string};
  currencyCode: string = '';
  
  constructor(
    public translateService: TranslationService,
    public translate: TranslateService,
    public customerService:CustomerService
  ) {}

  ngOnInit() {
    this.currencyCode = sessionStorage.getItem('currency');
  }
  ngAfterViewInit(){
    this.userPreference = { currencyCode:this.currencyCode,currencySymbol:this.currencySymbolOut.nativeElement.innerHTML }
    this.customerService.setCurrencySymbol(this.userPreference);
  }
}
