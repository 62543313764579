import { Component, EventEmitter, Output } from "@angular/core";
import { TranslationService } from "services/translation.service";

@Component({
  selector: "app-hide-show-toggle",
  templateUrl: "./hide-show-toggle.component.html",
  styleUrl: "./hide-show-toggle.component.scss",
})
export class HideShowToggleComponent {
  @Output() toggleEvent: EventEmitter<any> = new EventEmitter();
  isVisible: boolean = true;

  constructor(public translateService: TranslationService) {}
  ngOnInit() {
  }

  toggleVisibility() {
    this.isVisible = !this.isVisible;
    this.toggleEvent.emit(this.isVisible);
  }
}
